'use client';
import type {NextErrorProps} from '@/types/next-error-props';
import {GlobalError} from '@/components/error/global-error';

export default function Error({error, reset}: NextErrorProps) {
  return (
    <html>
      <body>
        <GlobalError error={error} reset={reset} />
      </body>
    </html>
  );
}
