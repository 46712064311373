'use client';
import type {NextErrorProps} from '@/types/next-error-props';
import {SvgMalware} from '@/ui/icon/malware';
import '@/components/error/global-error-styles.css';

import {useExceptionLogging} from '@/hooks/use-exception-logging/use-exception-logging';

export function GlobalError({error, reset}: NextErrorProps) {
  useExceptionLogging(error);

  return (
    <div className="container">
      <SvgMalware size="40" />
      <h1 className="title">Don&apos;t worry, your network is still intact. </h1>
      <div className="description">
        <div>Thanks for spotting the bug blocking your path - our</div>
        <div>team is pulling together an extermination plan now.</div>
      </div>
      <button className="button" onClick={() => reset()}>
        Continue Expanding Your Network
      </button>
    </div>
  );
}
